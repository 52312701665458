.app {
  width: 100%;
  height: 100vh;
  background: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.header {
  width: 100%;
  height: 10vh;
  background: #295b6d;
  display: flex;
  align-items: center;
}

.footer {
  width: 100%;
  height: 5vh; 
  background: #295b6d;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0px;
}

.footer-icons {
  width: 100%;
  padding-right: 30px;
  position: absolute;
  bottom: 8; 
  display: flex;
  justify-content: flex-end
}

.screen {
  width: 100%;
  height: 90vh;
  background-color: white;
  display: flex;
  flex-direction: column;
}

.item-btn:hover {
  opacity: 0.7;
}
.item-btn:focus {
  opacity: 1;
}

.link-item {
  background: white;
}

.link-item:hover {
  background: #f2f2f2;
}

.button1 {
  background-color: transparent; 
  color: white; 
  border: 0px solid black;
  font-weight: bold;
  margin-left: 40px;
  font-size: 18px;
}

.button1:hover {
  background-color: transparent;
  color: white;
  border-bottom: 1px solid white;
}

/* .button1:focus {
  background-color: transparent;
  color: white;
  border-bottom: 1px solid white;
} */

.button2 {
  background-color: white; 
  color: black; 
  border: 0px solid black;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  position: absolute;
  right: 30px;
  width: 120px;
  height: 40px;
}

.button2:hover {
  color: #295b6d;
}

.button3 {
  background-color: orange; 
  color: white; 
  border: 2px solid orange;
  border-radius: 50px;
  font-size: 24px;
  font-weight: bold;
  width: 320px;
  height: 60px;
  margin-top: 40px;
}

.button3:hover {
  opacity: 0.9;
}

.button4 {
  background-color: orange; 
  color: white; 
  border: 2px solid orange;
  border-radius: 50px;
  font-size: 24px;
  font-weight: bold;
  width: 320px;
  height: 60px;
  margin-top: 40px;
}

.button4:hover {
  opacity: 0.9;
}

.title {
  font-size: 90px;
  color: orange; 
  font-weight: bold; 
  margin-left: 100px;
  margin-top: 100px
}

.sub-title {
  font-size: 40px;
  color: white; 
  font-weight: bold; 
  margin-left: 100px;
  margin-top: 0px
}

.g-title {
  height: 110px; 
  object-fit: contain
}

.g-img {
  width: 300px; 
  height: 300px; 
  object-fit: contain
}

.text-shadow {
  text-shadow: 2px 2px black;
}

.social-icon {
  opacity: 1;
}

.social-icon:hover {
  opacity: 0.8;
}

.icon {
  width: 80px;
  height: 80px;
}

.icon-img{
  width: 50px;
  height: 50px
}

.form {
  width: 600px;
}

.desgin-txt1 {
  font-size: 60px;
  font-weight: normal; 
  color: white; 
  align-self: flex-end;
  margin-bottom: 10px; 
  width: 48%;
}
.desgin-txt2 {
  font-size: 22px;
  font-weight: bold; 
  color: white; 
  align-self: flex-end;
  margin-right: 50px;
  margin-top: 5px;
  text-align: center;
}
.desgin-txt3 {
  font-size: 65px;
  font-weight: normal; 
  color: white; 
  align-self: flex-end;
  margin-top: 0px; 
  width: 48%;
}

.gallery-img-panel-parent {
  display: flex; 
  align-items: center; 
  align-self: center; 
  justify-content: center; 
  width: 100%; 
  overflow: scroll; 
  padding-left: 20px; 
  padding-right: 20px;
  padding-top: 10px
}

.gallery-img-panel {
  height: 135px;
  display: flex;
  align-items: center;
  overflow: scroll;
}

.gallery-img {
  width: 120px; 
  height: 120px; 
  object-fit: cover;
  border-radius: 20px;
}

.gallery-txt {
  margin-top: -10px;
  width: 500px;
  height: 150px; 
  object-fit: contain
}

.desgin-img-panel-parent {
  display: flex;
  margin-top: 0px; 
  flex-direction: column;
  align-items: center; 
  align-self: center;  
  width: 100%; 
  overflow: scroll;
  height: 80vh;
}

.design-img-panel {
  padding: 0px;
  display: flex;
  border-radius: 10px; 
  align-items: center;
}

.desgin-img {
  border-radius: 8px;
  width: 150px; 
  height: 150px; 
  object-fit: cover
}

.download-title {
  color: white; 
  text-shadow: 1px 1px 2px black; 
  font-weight: bold;
}

.download-btn {
  height: 70px;
  width: 200px;
}

.download-btn-apple {
  height: 50px;
  width: 50px;
  object-fit: contain;
}

.download-btn-google {
  height: 45px;
  width: 45px;
  margin-left: 5px;
  object-fit: contain;
}

.download-btn-txt1 {
  font-size: 14px; 
  color: #fff; 
  width: 100%; 
  text-align: start; 
}

.download-btn-txt2 {
  font-size: 22px; 
  color: #fff;
  margin-top: -6px;
}

.download-section {
  margin-right: 20px; 
}

.scroll-bottom-padding {
  padding-bottom: 0px;
}

@media screen and (max-width: 1180px) {
  .desgin-txt1 {
    font-size: 60px;
    margin-bottom: 0px; 
  }
  .desgin-txt2 {
    font-size: 20px;
    margin-right: 50px;
    margin-top: 0px;
  }
  .desgin-txt3 {
    font-size: 55px;
    margin-top: 0px; 
    width: 48%;
  }
}

@media screen and (max-width: 1080px) {
  .desgin-txt1 {
    font-size: 55px;
    margin-bottom: 0px; 
    width: 48%;
  }
  .desgin-txt2 {
    font-size: 18px;
    margin-right: 50px;
    margin-top: 0px;
  }
  .desgin-txt3 {
    font-size: 50px;
    margin-top: 0px; 
    width: 48%;
  }
}

@media screen and (max-width: 980px) {
  .desgin-txt1 {
    font-size: 55px;
    margin-bottom: 0px; 
    width: 48%;
  }
  .desgin-txt2 {
    font-size: 18px;
    margin-right: 50px;
    margin-top: 0px;
  }
  .desgin-txt3 {
    font-size: 50px;
    margin-top: 0px; 
    width: 48%;
  }
  .design-img-panel {
    width: 145px;
  }
  .desgin-img {
    width: 130px; 
    height: 130px; 
  }
  .gallery-img-panel {
    height: 135px;
  }
  .gallery-img {
    width: 120px; 
    height: 120px; 
  }
}

@media screen and (max-width: 880px) {
  .button1 {
    font-size: 16px;
    margin-left: 20px;
  }
  .button2 {
    right: 15px;
    width: 100px;
    height: 30px;
    font-size: 14px;
    font-weight: normal;
  }
  .title {
    font-size: 70px;
    margin-top: 70px;
    margin-left: 50px;
  }
  .sub-title {
    font-size: 30px;
    margin-left: 50px;
  }
  .button3 {
    font-size: 20px;
    font-weight: bold;
    width: 280px;
    height: 55px;
    margin-top: 50px;
  }
  .icon {
    width: 60px;
    height: 60px;
  }
  .icon-img{
    width: 35px;
    height: 35px
  }
  .desgin-txt1 {
    font-size: 55px;
    margin-bottom: 0px; 
    width: 48%;
  }
  .desgin-txt2 {
    font-size: 16px;
    margin-right: 50px;
    margin-top: 0px;
  }
  .desgin-txt3 {
    font-size: 50px;
    margin-top: 0px; 
    width: 48%;
  }
  .design-img-panel {
    width: 140px;
  }
  .desgin-img {
    width: 125px; 
    height: 125px; 
  }
  .gallery-img-panel {
    height: 130px;
  }
  .gallery-img {
    width: 115px; 
    height: 115px; 
  }
  .gallery-txt {
    width: 450px;
    height: 120px; 
    object-fit: contain;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 780px) {
  .button1 {
    font-size: 14px;
    margin-left: 10px;
  }
  .button2 {
    position: relative;
    margin-left: 30px;
    width: 90px;
    height: 30px;
    font-size: 13px;
    font-weight: normal;
  }
  .title {
    font-size: 60px;
    margin-top: 70px;
    margin-left: 50px;
  }
  .sub-title {
    font-size: 25px;
    margin-left: 50px;
  }
  .desgin-txt1 {
    font-size: 50px;
    margin-bottom: 10px; 
    width: 48%;
  }
  .desgin-txt2 {
    font-size: 14px;
    margin-right: 50px;
    margin-top: 5px;
  }
  .desgin-txt3 {
    font-size: 45px;
    margin-top: 10px; 
    width: 48%;
  }
  .design-img-panel {
    width: 135px;
  }
  .desgin-img {
    width: 120px; 
    height: 120px; 
  }
  .gallery-img-panel {
    height: 130px;
  }
  .gallery-img {
    width: 115px; 
    height: 115px; 
  }
}

@media screen and (max-width: 680px) {
  .button1 {
    font-size: 12px;
    margin-left: 10px;
  }
  .button2 {
    position: relative;
    margin-left: 20px;
    width: 80px;
    height: 30px;
    font-size: 12px;
    font-weight: normal;
  }
  .form {
    width: 400px;
  }
  .footer {
    justify-content: flex-start;
    padding-left: 20px;
  }
  .desgin-txt1 {
    font-size: 40px;
    margin-bottom: 10px; 
    width: 48%;
  }
  .desgin-txt2 {
    font-size: 12px;
    margin-right: 10px;
    margin-top: 0px;
  }
  .desgin-txt3 {
    font-size: 35px;
    margin-top: 10px; 
    width: 48%;
  }
  .design-img-panel {
    width: 130px;
  }
  .desgin-img {
    width: 115px; 
    height: 115px; 
  }
  .gallery-img-panel {
    height: 130px;
  }
  .gallery-img {
    width: 115px; 
    height: 115px; 
  }
}

@media screen and (max-width: 580px) {
  .button1 {
    font-size: 11px;
    margin-left: 10px;
  }
  .button2 {
    position: relative;
    margin-left: 30px;
    width: 80px;
    height: 25px;
    font-size: 10px;
    font-weight: normal;
  }
  .footer {
    justify-content: flex-start;
    padding-left: 20px;
  }
  .desgin-txt1 {
    font-size: 38px;
    margin-bottom: 10px;
    width: 48%;
  }
  .desgin-txt2 {
    font-size: 10px;
    margin-right: 10px;
    margin-top: 0px;
  }
  .desgin-txt3 {
    font-size: 33px;
    margin-top: 10px; 
    width: 48%;
  }
  .design-img-panel {
    width: 90px;
  }
  .desgin-img {
    width: 75px; 
    height: 75px; 
  }
  .gallery-img-panel {
    height: 125px;
  }
  .gallery-img {
    width: 110px; 
    height: 110px; 
  }
  .gallery-txt {
    width: 400px;
    height: 100px; 
    object-fit: contain;
    margin-top: 0px;
    margin-bottom: 10px;
  }
  .download-title {
    font-size: 14px;
    color: white; 
    text-shadow: 1px 1px 2px black; 
    font-weight: bold;
  }
  .button4 {
    font-size: 18px;
    font-weight: bold;
    width: 220px;
    height: 45px;
    margin-top: 0px;
  }
}

@media screen and (max-width: 480px) {
  .button1 {
    font-size: 10px;
    margin-left: 3px;
  }
  .button2 {
    position: relative;
    margin-left: 20px;
    width: 65px;
    height: 25px;
    font-size: 9px;
    font-weight: normal;
  }
  .footer {
    justify-content: flex-start;
    padding-left: 20px;
  }
  .desgin-txt1 {
    font-size: 28px;
    margin-bottom: 20px;
    width: 46%;
  }
  .desgin-txt2 {
    font-size: 8px;
    margin-right: 15px;
    margin-top: 0px;
  }
  .desgin-txt3 {
    font-size: 23px;
    margin-top: 20px; 
    width: 46%;
  }
  .design-img-panel {
    width: 85px;
  }
  .desgin-img {
    width: 70px; 
    height: 70px; 
  }
  .gallery-img-panel-parent {
    padding-top: 15px
  }
  .gallery-img-panel {
    height: 120px;
  }
  .gallery-img {
    width: 105px; 
    height: 105px; 
  }
  .download-btn {
    height: 60px;
    width: 150px;
  }
  .download-btn-apple {
    height: 35px;
    width: 35px;
  }
  .download-btn-google {
    height: 32px;
    width: 32px;
    margin-left: 3px;
  }
  .download-btn-txt1 {
    font-size: 9px; 
    color: #fff; 
    width: 100%; 
    text-align: start; 
  }
  .download-btn-txt2 {
    font-size: 14px; 
    color: #fff;
    margin-top: 0px;
  }
  .download-section {
    margin-right: 12px; 
  }
  .scroll-bottom-padding {
    padding-bottom: 70px;
  }
}

.mobile-frame-height {
  min-height: 400px;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .mobile-frame-height {
    min-height: 320px;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .mobile-frame-height {
    min-height: 320px;
  }
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .mobile-frame-height {
    min-height: 320px;
  }
}
/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .mobile-frame-height {
    min-height: 320px;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .mobile-frame-height {
    min-height: 400px;
  }
}

.mobile-shadow {
  box-shadow: 0px 1px 1px 1px #E6E6E6;
}
.mobile-shadow-android {
  box-shadow: 0px 2px 1px 1px #E6E6E6;
}
.screen-shadow {
  box-shadow: 0px 0px 1px 1px ;
}
.mobile-shadow-top {
  box-shadow: 0px -1px 1px 1px #E6E6E6;
}
.mobile-shadow-bottom {
  box-shadow: 0px 1px 1px 1px #E6E6E6;
}

/* .portfolio_slider .slick-dots,
.testimonial_slider_info .slick-dots {
  bottom: 0px;
  margin-top: 30px;
  position: relative;
}
.portfolio_slider .slick-dots li,
.testimonial_slider_info .slick-dots li {
  margin: 0px;
  width: auto;
  height: auto;
}
.portfolio_slider .slick-dots li + li,
.testimonial_slider_info .slick-dots li + li {
  margin-left: 8px;
}
.portfolio_slider .slick-dots li.slick-active button,
.portfolio_slider .slick-dots li button:hover,
.testimonial_slider_info .slick-dots li.slick-active button,
.testimonial_slider_info .slick-dots li button:hover {
  transform: scale(1);
  background-color: #0db3c7;
  opacity: 1;
}
.portfolio_slider .slick-dots li button,
.testimonial_slider_info .slick-dots li button {
  padding: 0px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #999;
  opacity: 0.3;
  transform: scale(0.7);
  transition: all 0.3s linear;
}
.portfolio_slider .slick-dots li button:before,
.testimonial_slider_info .slick-dots li button:before {
  display: none;
}
.testimonial_area_two {
  padding: 120px 0px;
}
.testimonial_slider_info {
  padding-left: 70px;
}
.testimonial_slider_info .section_title_two {
  margin-bottom: 30px;
}
.testimonial_slider_info .item:focus {
  outline: none;
}
.testimonial_slider_info .author_img {
  width: 60px;
  height: 60px;
  border-radius: 30px;
  overflow: hidden;
  margin-right: 30px;
  border: 2px solid rgba(228, 235, 247, 0.4);
} */